import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'


const Seo = ({title}) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title,
                    description,
                    siteUrl,
                    image
                }
            }
        }
    `)


    const { pathname } = useLocation()

    const pageTitle = title ? `${title} | ${data.site.siteMetadata.title}` : `Latuss`
    const img_location = `${data.site.siteMetadata.siteUrl}/${data.site.siteMetadata.image}`

    const currentUrl = `${data.site.siteMetadata.siteUrl}${pathname}`
    // site metadata ; 
    // open graph: url, title, description, image
    return (
        <Helmet title={ pageTitle }>
            <meta name="description" content={data.site.siteMetadata.description} />
            <meta property="og:title" content={ pageTitle } />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:description" content={data.site.siteMetadata.description} />
            <meta property="og:image" content={img_location} />
            <link rel="canonical" href={currentUrl}/>
        </Helmet>
    )
}

export default Seo